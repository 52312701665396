<template>
  <v-dialog
    v-model="isDialogVisible"
    persistent
    max-width="600px"
  >
    <template #activator="{ on, attrs }">
      <v-btn
        color="success"
        dark
        v-bind="attrs"
        v-on="on"
      >
        Create Campaign
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        <span class="headline">Create Campaign</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="12"
            >
              <v-text-field
                v-model="name"
                label="Campaign Name"
                dense
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-autocomplete
                v-model="type"
                :items="['video', 'image', 'image-gallery']"
                label="Type"
                dense
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          outlined
          @click="isDialogVisible = false"
        >
          Close
        </v-btn>
        <v-btn
          color="success"
          @click="createCampaign(); isDialogVisible = false"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref } from '@vue/composition-api'
import api from '../../api'

export default {
  data() {
    return {
      name: '',
      type: 'image',
    }
  },
  methods: {
    createCampaign() {
      const vm = this
      const data = {
        Name: vm.name,
        Type: vm.type,
      }

      function reload() {
        window.location.reload()
      }

      api.createCampaign(data, reload)
    },
  },
  setup() {
    const isDialogVisible = ref(false)

    return { isDialogVisible }
  },
}
</script>
