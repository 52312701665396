<template>
  <v-card>
    <v-data-table
      :headers="headers"
      :items="items"
      item-key="full_name"
      class="table-rounded"
      hide-default-footer
      disable-sort
    >
      <!-- name -->
      <template #[`item.full_name`]="{item}">
        <div class="d-flex flex-column">
          <span class="d-block font-weight-semibold text--primary text-truncate">{{ item.full_name }}</span>
          <small>{{ item.post }}</small>
        </div>
      </template>
      <template #[`item.Status`]="{item}">
        <v-chip
          small
          :color="statusColor[item.Status]"
          class="font-weight-medium"
        >
          {{ item.Status }}
        </v-chip>
      </template>

      <template #[`item.Action`]="{item}">
        <v-btn
          x-small
          class="mr-1"
          color="primary"
          dark
          :to="'/campaign-detail?campaign_id='+item.CampaignID"
        >
          Detail
        </v-btn>
        <campaign-activate
          v-if="item.Status!='active'"
          class="ma-1"
          :campaign="item"
        ></campaign-activate>
        <campaign-pause
          v-if="item.Status=='active'"
          class="ma-1"
          :campaign="item"
        ></campaign-pause>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mdiSquareEditOutline, mdiDotsVertical } from '@mdi/js'
import CampaignActivate from './CampaignActivate.vue'
import CampaignPause from './CampaignPause.vue'
import api from '../../api'

export default {
  components: {
    CampaignActivate,
    CampaignPause,
  },
  data() {
    return {
      items: [],
      showid: '',
    }
  },
  mounted() {
    const vm = this
    function setCampaign(campaigns) {
      vm.items = campaigns
    }
    api.campaigns(setCampaign)
  },
  setup() {
    const statusColor = {
      /* eslint-disable key-spacing */
      active: 'primary',
      pause: 'success',
      Rejected: 'error',
      inactive: 'warning',
      Applied: 'info',
      /* eslint-enable key-spacing */
    }

    return {
      headers: [
        { text: 'Campaign ID', value: 'CampaignID' },
        { text: 'Name', value: 'Name' },
        { text: 'Type', value: 'Type' },
        { text: 'Tag', value: 'Tag' },
        { text: 'Created At', value: 'CreatedDateTime' },
        { text: 'Status', value: 'Status' },
        { text: 'Action', value: 'Action' },
      ],
      statusColor,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiDotsVertical,
      },
    }
  },
}
</script>
